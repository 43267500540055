.export-as-png-to-clipboard-button-wrapper {
  width: 100%;
  position: sticky;
}
.export-as-png-to-clipboard-button {
    position: absolute;
    top: 0.25em;
    right: 0.2em;
}
.export-as-png-to-clipboard-button .fa {
  font-size: 0.2em; /* adjust as needed */
}

.transparent-button:hover {
    opacity: 1;
}