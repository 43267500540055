.song-videos {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:1rem;
}
.video {
    display: flex;
    width:15rem;
    height: 10rem;
    box-sizing: content-box;
    border-radius: 8px;
    overflow: hidden;
}
@media (orientation: portrait) {
    .video {
        display: flex;
        width: 20rem;
        height: 13rem;
        box-sizing: content-box;
    }
}
    
