/* src\components\songs\ScoreEdit.css */
.score-edit-form {
    display:flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .score-edit-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
  }
  
  .score-edit-current-scores {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-grow: 1;
    gap:0.25rem;
    min-width:50%;
  }
  
  .score-edit-container .primary-button {
    font-size:0.5rem;
    flex-basis: 25rem;
    padding:0.1rem 0.5rem;
    flex: 1;
  }
  
  .score-edit-item-button {
    display:flex;
  }
  
  .score-edit-input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
  
  .score-edit-input-container input{
    font-size: 0.5rem;
    width: 15rem;
    margin-right: 0.5rem;
  }
  
  .score-edit-input-item .primary-button {
    margin-right: 0.5rem;
  }
  
  .score-edit-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
  }  
  
  .score-edit-button-item .primary-button {
    width: 10rem;
  }
  
  .score-edit-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    font-size:0.75rem;
  }
  
  .score-edit-item:hover {
    background-color: var(--secondary-color);
  }
  .score-edit-current-scores {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    flex-grow:1;
    
  }
  
  @media (orientation: portrait)
  {
    .score-edit-form {
      display: none;
    }
  }
  