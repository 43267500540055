.export-as-xml-button-wrapper {
    width: 100%;
    position: sticky;
  }
  
  .export-as-xml-button {
      position: absolute;
      top: 0.2em;
      right: 2.6em;
  }
  
  .export-as-xml-button .fa {
    font-size: 0.2em; /* adjust as needed */
  }
  
  .transparent-button:hover {
      opacity: 1;
  }
  @media (orientation: portrait) 
{
    .export-as-xml-button {
        right: 2.8em;
    }
  
}