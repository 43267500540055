.sing-day-song-edit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  gap: 0.5em;
}
.sing-day-song-edit-part-name {
  font-weight: bold;
  flex-basis: 10em;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
}

.sing-day-song-edit-song-name {
  flex-basis: 15em;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sing-day-song-edit-part-dropdown {
  flex-shrink: 1;
  flex-grow: 0;
  font-size: 1em;
  border-radius: 8px;
}
.sing-day-song-edit-button-container {
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
}
.sing-day-song-edit-delete-song-button {
  display: flex;
  flex-basis: 1.2em;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 0.75em;
  padding: 0.25em;
  cursor: pointer;
  background-color: transparent;
  color: var(--dark-color);
  border: none;

}
.sing-day-song-edit-button {
  flex-shrink: 1;
  flex-grow: 0;
  font-size: 0.75em;
  padding: 0.05em;
  cursor: pointer;
  white-space: nowrap;


  background-color: var(--light-gray-color);
    border: 1px solid var(--black-color);
    color: var(--secondary-text-color);
    border-radius: 8px;
    min-height: 1em;
    padding: 0 1em;
    font-size: 1em;
    flex-basis: 10em;
}
.sing-day-song-edit-dropdown-container {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  justify-content: space-around;
  align-items: center;
}
