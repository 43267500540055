.song-title {
  display: flex;
  text-decoration: none;
  color: var(--black-color);
  break-inside: avoid;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size:1rem;
}

.song-title:hover {
  background-color: var(--secondary-color);
  color: var(--black-color);
}

.song-title a {
  text-decoration: none;
}
.song-name {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  flex-grow:0;
  flex-shrink:1;
  height: 1.25rem;
}
.song-indicators {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}


.video-indicator {
  background-image: url("./icons/youtube_ico.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 1.5rem;
  width: 1.5rem;
}

.score-indicator {
  background-image: url("./icons/music.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 1.5rem;
  width: 1.5rem;
}

.inactive {
  filter:  opacity(30%);
}