.keyboard {
    display: flex;
    position: relative;
    height: 100px;
    width: 200px;
  }
  
  .white-key {
    background-color: white;
    height: 100%;
    width: 25px;
    border: 1px solid black;
  }
  
  .black-key {
    background-color: black;
    height: 60%;
    width: 15px;
    position: absolute;
    margin-left: 0px;
    z-index: 1;
  }
  
  /* Positioning black keys */
  .key.C + .key.black-key { left: 17.5px; }
  .key.D + .key.black-key { left: 46.5px; }
  .key.F + .key.black-key { left: 100px; }
  .key.G + .key.black-key { left: 126.5px; }
  .key.A + .key.black-key { left: 155px; }
  