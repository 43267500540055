.song-add-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    gap:1rem;
}
.song-add-title-container {
    width: 90%;
}
.song-add-title {
    width: 100%;
    height : 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;

}
.song-add-title input {
    display: flex;
    flex-basis: 20rem;
}

.song-add-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    flex-shrink: 1;
    flex-grow: 1;
}
.song-add-content-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink:1;
    width: 50%;
    gap: 1rem;
}
.song-add-content-item textarea {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1rem;
    border-radius: 8px;
}
.song-add-buttons-container
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:0.5rem;
    align-items: center;
    width: 100%;
    flex-grow:1;
    flex-shrink: 1;
}

.song-add-buttons-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
}

@media (orientation:portrait) {
    .song-add-content-container {
        flex-direction: row;
    }
    .song-add-content-item textarea {
        font-size: 0.75rem;
    }
}