.chord-diagram-display {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    overflow-y: auto;
    z-index: 9999;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    color: var(--primary-color);
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .chord-diagram-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }

  .section-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  