.song-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap:1rem;
    padding-left:2rem;
    padding-right:2rem;
}
.song-top-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 4rem;
    width: 100%;
}
.song-header {
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    width: 100%;
}
.song-tag-panel {
    padding-left: 4rem;
    width:100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap:0.5rem;
  }
.song-bottom-panel {
    width: 100%;   
    flex-direction: row;

    display: flex;
    height: 100%;
    align-items: flex-start;
}

.song-left-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left:2rem;
    height: 100%;
    flex-shrink: 0;
    flex-grow: 5;
    gap:3rem;
}
.chord-line {
    color: var(--primary-color);
    padding-bottom:0.3rem;
}
.only-chord-line .chord-line {
    padding-top:1.5rem;
    padding-bottom:1.5rem;
}
.empty-line {
    height:1.5rem;
}
.text-line {
    padding-bottom:0.5rem;
}
.song-right-panel
{
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    flex-grow:1;
    flex-shrink: 1;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    height: 100%;
}
.song-right-panel-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap:1rem;
    flex:1;
}
.song-right-panel-transpose {
    box-sizing:border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 15rem;
}
.transpose-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-basis:15rem;
    flex-grow: 0;
    flex-shrink: 1;
}
.transpose-button-left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
}
.transpose-button-right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;

}
.transpose-button-right, 
.transpose-button-left {
    background-color: var(--light-gray-color);
    color: var(--secondary-text-color);
    border: 1px solid var(--black-color);
    justify-content: center;
    align-items: center;
    display:flex;
    flex-direction: column;
    font-size: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
    padding:0;
}
.primary-button-mid {
    border-radius: 0;
    border: 1px solid var(--black-color);
    background-color: var(--light-gray-color);
    color: var(--secondary-text-color);
    display:flex;
    flex-direction: row;
    font-size: 1rem;
    flex-grow: 10;
    flex-shrink: 1;
    text-align: center;
    justify-content: center;
    border-left: 0;
    border-right: 0;
}

.song-right-panel-container button.primary-button {
    box-sizing: border-box;
    flex-grow: 2;
    flex-basis: 15rem;
} 

.song-right-panel-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.song-videos-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom:2rem;
    flex: 1;
}
.song-lyrics {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
@media (orientation: portrait) {

    .primary-button-mid {
        padding: 0.75rem;
        box-sizing: border-box;
        height: 3rem;
        border-radius: 0;
        border-color: var(--primary-color);
        color: #f2fff0;
        background-color: var(--primary-color);
        flex-basis: 2rem;
    }

    .transpose-container {
        flex-basis: 7rem;
        height: 100%;
    }

    .transpose-button-right, 
    .transpose-button-left {
        height: 3rem;
        padding: 1rem;
        border-color: var(--primary-color);
        color: #f2fff0;
        background-color: var(--primary-color);
    }

    .transpose-button-left {
        border-radius: 1.5rem;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0px;
        padding-right: 0.25rem;
    }
    .transpose-button-right {
        border-radius: 1.5rem;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 0px;   
        padding-left: 0.25rem; 
    }


    .song-content {
        height: auto;
        padding-left:0.5rem;
        padding-right:0.5rem;
    }

    .song-top-panel {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0rem;
        gap: 2rem;
        text-align: center;
    }
    .song-header {
        justify-content: center;
    }
    .song-tag-panel {
        padding-left: 0rem;
        justify-content: center;
    }

    .song-bottom-panel {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    .song-left-panel {
        padding-left:0rem;

    }

    .song-right-panel-container {
        flex-direction: row;
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        align-items: center;
        background-color: #fff;
        bottom:0;
        height: 3rem;
        justify-content: center;
        position:fixed;
        width: 100%;
        z-index: 1000;
        gap: 0.5rem;
    }

    .song-right-panel-container button.primary-button {
        box-sizing: border-box;
        flex-grow: 0;
        flex-basis: 2rem;
    } 

    .song-right-panel-transpose {
        
        width: fit-content;
    }

    .song-videos-panel {
        padding-bottom:8rem;
    }

}
