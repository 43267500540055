/* src\components\songs\TagEdit.css */
.tag-edit-form {
  display:flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.tag-edit-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  }
  .tag-edit-container .primary-button {
    font-size:0.5rem;
    flex-basis: 25rem;
    padding:0.1rem 0.5rem;
    flex: 1;
  }
  .tag-edit-current-tags {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-grow: 1;
    gap:0.25rem;
    max-width:50%;
  }
  
  .tag-edit-input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .tag-edit-input-item input{
    font-size: 0.5rem;
    width: 15rem;
    margin-right: 0.5rem;

  }
  .tag-edit-input-item .primary-button {
    margin-right: 0.5rem;
  }
  .tag-edit-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
  }
  .tag-edit-buttons-container .primary-button {
    width:10rem;
  }

  .tag-edit-input-container input {
    flex-grow: 1;
    flex-shrink:0;
  }
 
  @media (orientation: portrait) {
    .tag-edit-current-tags {
      max-width: 90vw;
    }
    .tag-edit-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.5rem;
    }
    .tag-edit-container .primary-button {
      padding: 0.1rem 0.5rem;
      font-size: 0.75rem;
    }
    .tag-edit-container input {
      font-size: 0.65rem;
      margin-top: 0.5rem;
    }
    .tag-edit-buttons-container {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }