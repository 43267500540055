.sing-day-song-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  gap: 0.5em;
}
.sing-day-song-part-name {
    font-weight: bold;
    flex-basis:8em;
    flex-shrink:0;
    flex-grow:1;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
}
.sing-day-song-song-name {
    flex-basis:20em;
    flex-shrink:1;
    flex-grow:1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}