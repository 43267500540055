.fretboard {
    width: 300px;
    height: 200px;
    overflow: visible;
  }
  
  .fret {
    fill: gray;
  }
  
  .string {
    stroke: black;
    stroke-width: 2;
  }
  .starting-fret {
    font-size: 1em;
  }