.user-statuses-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  width: 12em;
  /* 2x max circle size */
}

.status-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 6em;
  height: 6em;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.status-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  text-align: center;
  width: 1em;
  height: 1em;
  font-size: 0.65em;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
}

.user-statuses-lines {
  position: absolute;
  z-index: 0;
}

/* Status-specific styles */
.status-circle.obecność {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  order: 1;
  /* top-left */
}

.status-circle.spóźnienie {
  background-color: var(--warning-color);
  color: var(--secondary-text-color);
  order: 2;
  /* top-right */
}

.status-circle.noinfo {
  background-color: var(--gray-color);
  color: var(--primary-text-color);
  order: 3;
  /* bottom-left */
}

.status-circle.nieobecność {
  background-color: var(--danger-color);
  color: var(--primary-text-color);
  order: 4;
  /* bottom-right */
}

.user-statuses-user-list {
  position: absolute;
  top: 50%;
  left: 0;
  padding: 0.5em;
  background-color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  z-index: 10;
}

.user-statuses-status-name {
  flex-basis: 10em;
  flex-shrink: 0;
  flex-grow: 1;
  padding-bottom: 0.5em;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}





.status-summary {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 0.5em;
  box-sizing: border-box;
}

.status-summary-name {
  font-weight: bold;
}

/* Top-left */
.status-summary.obecność {
  order: 1;
}

/* Top-right */
.status-summary.spóźnienie {
  order: 2;
}

/* Bottom-left */
.status-summary.noinfo {
  order: 3;
}

/* Bottom-right */
.status-summary.nieobecność {
  order: 4;
}

.user-statuses-summary {
  display: none;
}

.for-export .user-statuses-summary {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1em;
  /* spacing from the existing component */
}