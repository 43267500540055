.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  height: 100vh;
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
}

.login-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 1000px;
  height: 100%;
}

.logo-mobile {
  display: none;
}
@media (orientation=portrait) {
    .content {
      padding-top: 0rem;
    }
    .logo-mobile {
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 1rem;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }