.burger-menu {
  display: none;
}

.burger-menu nav a {
  display: block;
  margin-bottom: 1rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.burger-button {
  display: none;
}

@media (orientation: portrait) {
  .burger-menu {
    position: fixed;
    width: 10rem;
    height: fit-content;
    top: 2.8rem;
    right: 0rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;
    border-bottom-left-radius: 1rem;
    z-index: 2000;
  }
  .burger-menu.hidden {
    display: none;
  }

  .burger-button {
    display: flex;
    position: fixed;
    top: 0.5rem;
    right: 1rem;
    background-color: #fff;
    font-size: 1.5rem;

    z-index: 1001;
    color: var(--primary-color);
  }
  .menu {
    display: none;
  }
}
.burger-item {
  position: relative;
}

.burger-dropdown {
  display: none;
  position: absolute;
  top: 0;
  left: -8rem;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 600;
}

.burger-item:hover .burger-dropdown {
  display: block;
}
