.login-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 1000px;
  height: 100%;
}

.login-left {
  display: flex;
  flex-basis: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.login-right {
  display: flex;
  background-image: url("./images/login_image.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  flex-basis: 50%;
  height: 100%;
  flex-grow: 1;
}
.login-box-container {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  height: 100%;
  min-width: 100%;
  padding-left: 0;
  margin-left: 0;
  justify-content: center;

}
.login-box {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  flex-basis: 30%;
}

.login-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}


.login-elements {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-logo {
  background-image: url("./images/spiewnik-logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 130px;
  width: 320px;
  margin-bottom: 3rem;
}
@media (orientation=portrait) {
  .login-page {
    flex-direction: column;
    min-width: 0;
    height: 100vh;
  }
  .login-left {
    height: 100vh;
  }
  .login-right {
    display: none;
    flex-basis: 0;
  }
  .login-box-container {
    background-color: #dfdfdf;
  }
  .login-box {
    background-color: rgba(255, 255, 255, 0.5);
    align-items: center;
    flex-basis: 100%;
  }
  .login-checkbox {
    background-color: #fff;
    width: fit-content;
    padding: 0.5rem;
  }
  .login-elements {
    background-color: #fff;
    padding-top: 2rem;
    padding: 3rem;
    box-sizing: border-box;
    align-items: center; /* center children horizontally */
    width: 100%; /* full width */
  }
  .login-logo {
    background-color: #fff;
  }
}
