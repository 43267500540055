.letter-navigation-container {
  position: sticky;
  bottom: 0.25rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
}
.letter-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-grow: 8;
  flex-shrink: 0;
}
.letter-space-box {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 2.25rem;
}

@media (orientation: portrait) {
  .letter-navigation-container {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }
  .letter-space-box {
    flex-basis: 0rem;
  }
  .song-list-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
