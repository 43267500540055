/* src/css/components/songs/SongScores.css */
.song-scores-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
}
.close-button {
  display: block;
  top: 0.25rem;
  right: 4rem;
  background: none;
  border: none;
  color: var(--black-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.close-button:hover {
  color: var(--contrast-color);
}

#cursorImg-0 {
  filter: invert(50%) sepia(1) saturate(0);
}

button.mdc-button .mdc-button__label {
  font-size: 0;
}

button.mdc-button .mdc-button__label::before {
  content: "Wycisz";
  font-size: 14px;
  visibility: visible;
}

button.mdc-button[aria-pressed="true"] {
  background-color: #008037;
  color: #fff;
}

button.mdc-button[aria-pressed="true"] .mdc-button__label::before {
  content: "Wyciszone";
  font-size: 14px;
}

.controls-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.controls-container .hide {
  display: none;
}

.metronome-toolbar {
  width: 20rem;
}

button[aria-label="Close Audio Controls"] {
  display: none;
}

.custom-control-panel {
  display: flex;
  flex-direction: column;
}

.custom-playback-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}
@media (orientation: portrait) {
  .song-scores-container {
    transform: translateY(-62%);
    padding-top: 2rem;
    scale: 0.5;
    position: relative;
    min-width: 800px;
    top: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .close-button {
    top: 2rem;
    right: 0.25rem;
  }
}
