.chord-diagram {
    position: relative;
    width: 300px;
    height: 200px;
  }
  
  .marker-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 200px;
    overflow: visible;
  }
  
  .marker {
    fill: var(--primary-color);
    r: 16;
  }
  .chord-diagram-wrapper {
    padding: 20px;
  }
  .finger-number {
    font-size: 18px;
    fill: #fff;
  }
  .polyline {
    stroke-width: 20;
    stroke: var(--primary-color);
    fill: none;
  }
  .marker-x {
    r: 12;
    fill: var(--contrast-color);
  }
  
  .marker-x-text {
    font-size: 10px;
    fill: white;
    text-anchor: middle;
  }
  .chord-diagram-chord-name {
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    margin-bottom:1em;
  }