.comment {
    border: 1px solid #ccc;
    padding: 0.5em;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: column;
  }
  
  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .comment-author {
    font-weight: bold;
    flex-grow: 5;
  }
  
  .comment-delete-button {
    flex-grow: 0;
    flex-shrink: 1;
    background: none;
    color: darkgrey;
    border: none;
    cursor: pointer;
    padding:0;
  }
  
  .comment-date {
    font-size: 0.8em;
    color: #666;
    margin-bottom: 10px;
  }
  
  .comment-content {
    white-space: pre-wrap; /* For preserving line breaks in the comment content */
  }
  