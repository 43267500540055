.add-comment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .comment-input {
    width: 90%;
    height: 80px;
    padding: 10px;
    border: 1px solid var(--primary-color);;
    border-radius: 5px;
    resize: none; /* Prevent resizing */
  }
  
  .submit-comment-button {
    width: 50%;
    border-radius: 8px;
    min-height: 1em;
    padding: 0 1em;
    font-size: 1em;
    border: 1px solid var(--black-color);
    flex-grow: 0;
    flex-shrink:0;
  }
  
  .submit-comment-button:hover {
    background-color: var(--primary-color);;
  }
  