.day-management-edit-mode-button-wrapper {
  width: 100%;
  position: sticky;
}

.day-management-edit-mode-button {
  position: absolute;
  top: 0.2em;
  left: 0.2em;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  display: block;
  padding: 0.25em;
  color: var(--primary-color);
  opacity: 0.3;
}

@media (orientation: portrait) {
  .day-management-edit-mode-button {
    font-size: 1.25rem;
  }
}