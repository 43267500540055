.day-texts-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-items: center;
    align-items: center;
    gap: 1em;
}

.day-texts-acclamation-label,
.day-texts-psalm-label {
    font-weight: bold;
}

.day-texts-acclamation,
.day-texts-psalm {
    font-style: italic;
    text-align: center;
}

.day-texts-acclamation-container,
.day-texts-psalm-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-items: center;
    align-items: center;
    gap: 0.2em;
}