.search-box-container {
  position: sticky;
  top: 3rem;
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: #fff;
  z-index: 500;
}
.search-box {
  display: flex;
  align-items: center;
  flex-basis: 50%;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  gap: 0.2rem;
}

.search-input {
  display: flex;
  flex-basis: 31rem;
  font: 1rem;
  border: none;
}
input.search-input:focus {
  outline: none;
}
.search-box:focus-within {
  outline: 1px solid var(--black-color);
}

.search-icon {
  display: flex;
  flex-basis: 1rem;
}

.clear-icon {
  display: flex;
  cursor: pointer;
  flex-basis: 1rem;
}

@media (orientation: portrait) {
  .search-box-container {
    position: fixed;
    top: auto;
    bottom: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
