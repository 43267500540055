.song-edit {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}
.song-edit-buttons-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}
.song-edit-buttons-item {
  width: 10rem;
  display: flex;
}
@media (orientation: portrait) {
  .song-edit-buttons-container {
    margin-top: 2rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}
