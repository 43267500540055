.admin-user-page-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .admin-user-dropdown-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
  }
  
  .admin-user-button-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  