.logo-container {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    height: 3rem;
    width: 8rem;
}
.logo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    flex-grow: 1;
    flex-shrink: 0;
    text-decoration: none;
    color: var(--black-color);
}



.logo-text {
    font-weight: bold;
    font-size: 1rem;
    width:100%;
    color: var(--black-color);
    display: flex;
    justify-content: flex-start;
}


.logo-img {
    display:flex;
    justify-self: flex-end;
    height: 1.5rem;
    flex-grow: 0;
    flex-shrink: 0;
}
