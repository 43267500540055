.song-to-word-button-wrapper {
    width: 100%;
    position: sticky;
  }
  
  .song-to-word-button {
    position: absolute;
    top: 0em;
    right: 1.5em;
  }
  
  .song-to-word-button .fa {
    font-size: 0.2em; /* adjust as needed */
  }
  
  .transparent-button:hover {
    opacity: 1;
  }
  
  @media (orientation: portrait) {
    .song-to-word-button {
      right: 2.1rem;
    }
  }