/* src\components\songs\VideoEdit.css */
.video-edit-form {
  display:flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.video-edit-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
  }
  
  .video-edit-current-videos {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-grow: 1;
    gap:0.25rem;
    min-width:50%;
  }
  .video-edit-container .primary-button {
    font-size:0.5rem;
    flex-basis: 25rem;
    padding:0.1rem 0.5rem;
    flex: 1;
  }
  .video-edit-link {
    font-size:0.5rem;
    width: 15rem;
    white-space: nowrap
  }
  .video-edit-item-button {
    display:flex;
  }
  .video-edit-input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .video-edit-input-item input{
    font-size: 0.5rem;
    width: 15rem;
    margin-right: 0.5rem;
  }
  .video-edit-input-item .primary-button {
    margin-right: 0.5rem;
  }
  .video-edit-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
  }  
  .video-edit-button-item .primary-button {
    width: 10rem;
  }
  .video-edit-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
  .video-edit-item:hover {
    background-color: var(--secondary-color);
  }
  
  @media (orientation: portrait)
  {
    .video-edit-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.5rem;
    }
    .video-edit-container .primary-button {
      padding: 0.1rem 0.5rem;
      font-size: 0.75rem;
    }
    .video-edit-container input {
      font-size: 0.65rem;
      margin-top: 0.5rem;
    }
    .video-edit-link {
      width: 16rem;
    }
    .video-edit-buttons-container {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }