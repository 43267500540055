.topScroll {
  position: fixed;
  right: 1rem;
  bottom: 0.3rem;
  color: var(--primary-color);
  border: solid 1px var(--primary-color);
  background-color: #fff;
  border-radius: 50%;
  padding: 0.1rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1200;
}

.topScroll i {
  color: var(--primary-color);
  font-size: 1rem;
}

@media (orientation: portrait) {
  .topScroll {
    bottom: 1.6rem;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.25rem;
  }
}