@keyframes slideIn {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  
  .message-box {
    position: fixed;
    top: 0;
    left: 1px;
    padding: 1rem;
    border: 1px solid;
    border-radius: 5px;
    background-color: #fff;
    animation: slideIn 1s ease-out forwards, fadeout 2s 2s forwards;
    z-index: 999;
  }
  
  .message-box.success {
    color: var(--success-color);
    border-color: var(--success-color);
  }
  
  .message-box.error {
    color: var(--error-color);
    border-color: var(--error-color);
  }
  
  .message-box.warning {
    color: var(--warning-color);
    border-color: var(--warning-color);
  }
  
  .message-box.info {
    color: var(--info-color);
    border-color: var(--info-color);
  }
  
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  