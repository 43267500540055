.menu {
  min-height: var(--menu-height);
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  background-color: #fff;
  z-index: 800;
}

.menu-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-shrink: 0;
  gap: 2rem;
}
.logout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
}
.menu-logo {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}
.menu-elements-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 8;
  flex-shrink: 1;
  gap: 2rem;
}
.menuElement {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  cursor: pointer;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  min-width: max-content;
  white-space: nowrap;
}

.menu a:hover {
  text-decoration: none;
}
.menuElement {
  position: relative;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  z-index: 600;
  flex-direction: column;
}

.menuElement:hover .dropdown {
  display: block;
}
.dropdown a {
  display: block; /* This will ensure each link takes the full width, moving to the next line */
  padding: 0.5rem; /* Some padding for better appearance */
}
@media (orientation: portrait) {
  .menu {
    display: none !important;
  }
}
