.day-management-list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    font-size:1em;
    gap: 1em;
    padding: 0.5em;
    max-width:81em;
}

@media (orientation: portrait) {
    .day-management-list-container {
        justify-content: center;
    }
}

@media (max-width: 109em) {
    .day-management-list-container {
        justify-content: center;
        max-width: 56em;
    }
}