.logout-button {
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1rem;
}

.logout-button:hover {
    filter: opacity(80%);
}
