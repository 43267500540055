.editable-tag {
    display: inline-block;
    background-color: #f5f5f5;
    border-radius: 3px;
    font-size: 0.5rem;
    color: #333;
    padding:0.2rem;
    margin: 0.1rem;
  }
  .editable-tag-remove-button {
    margin-left: 0.3rem;
    cursor: pointer;
  }