/* src/components/Loading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
}

.loading-spinner {
  border: 0.5rem solid #f3f3f3;
  border-top: 0.5rem solid var(--primary-color);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
