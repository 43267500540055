.day-management-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 26em;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 1em;
  gap: 1em;
}
.day-management-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
  gap: 0.2em;
  flex-grow: 0;
}
.day-management-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 25em;
}
.day-management-date {
  font-weight: bold;
  font-size: 1.5em;
}
.day-management-owners-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  width: 27em;
  flex-grow:0;
}
.day-management-organizers-container,
.day-management-psalmist-container,
.day-management-adoration-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.1em;
  width: 80%;
  white-space: nowrap;
  width: 27em;
}
.day-management-organizers-header,
.day-management-psalmist-header,
.day-management-adoration-header {
  font-weight: bold;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-management-organizers,
.day-management-psalmist,
.day-management-adoration {
  flex-shrink: 0;
  flex-grow: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.day-management-people-presence-container {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  width: 27em;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}
.day-management-songs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20em;
  flex-grow: 0;
}
.day-management-userstatus-buttons-container {
  display:flex;
    flex-grow:0;
    flex-shrink:0;
}
.day-management-button-userstatus-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: space-around;
  justify-content: center;
  width: 25em;
  flex-grow:0;
  flex-shrink:0;
}
.day-management-container-edit-mode-on {

  background-color: var(--light-primary-color);
    background-image: linear-gradient(to right,rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)),linear-gradient(
        rgba(255, 255, 255, 1), 
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 1)
    );
  transition: background-color 0.05s ease-in-out, background-image 0.05s ease-in-out;

}

.day-management-container-edit-mode-off {
  background-color: #fff;
  transition: background-color 0.05s ease-in-out, background-image 0.05s ease-in-out;
  background-image: linear-gradient(to right,rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)),linear-gradient(
    rgba(255, 255, 255, 1), 
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1)
    );
}
.day-management-people-header {
  font-weight: bold;
}


.day-management-disabled-message {
  color: red;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  height: 100%
}

.day-management-toggle-comments-button {
  width: 50%;
  border-radius: 8px;
  min-height: 1em;
  padding: 0 1em;
  font-size: 1em;
  border: 1px solid var(--black-color);
  flex-grow: 0;
  flex-shrink:0;
}
.flex-spacer {
  flex-grow: 1;
  min-height: 1em; 
}
.flex-spacer-no-grow {
  flex-grow: 0;
  min-height: 1em; 
}