.song-list {
  column-count: 3;
  column-gap: 1rem;
  padding: 0rem 2rem 2rem 2rem;
}
.song-list-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.25rem;
}
.song-letter {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 5%;
  justify-content: center;
  align-items: flex-start;
  font-weight: bold;
}
.song-letter::before {
  display: block;
  content: "";
  height: 25rem; /* The offset height */
  margin-top: -25rem; /* The negative value of the offset height */
  visibility: hidden;
}

@media (max-width: 100rem) {
  .song-list {
    column-count: 2;
  }
}

@media (max-width: 70rem) {
  .song-list {
    column-count: 1;
    padding: 0rem 1rem 2rem 1rem;
  }
}
@media (orientation: portrait) {
  .song-list {
    column-count: 1;
    padding: 0 0.5rem 3.5rem 0.5rem;
  }
  .song-list-row {
    flex-basis: 50%;
  }
}
