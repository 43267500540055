.keyboard-chord-diagram {
    padding: 20px;
  }
  
  .keyboard-chord-name {
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1em;
  }
  
  .keyboard-container {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
  }
  
  .dot {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: green; /* Default for non-sharp notes */
    top: 75%; /* Slightly lower for non-sharp notes */
    transform: translateY(-50%);
  }
  
  /* Light green color and higher position for sharp notes */
  .dot.sharp {
    top: 45%; /* Slightly higher for sharp notes */
    z-index: 2;
  }
  
  /* Adjusted positioning for dots on the keys, considering dot size and border width */
  .dot.C { left: calc((1 * 25px) - 12.5px - 5px + (1 * 1px)); }
  .dot.C.sharp { left: calc((1 * 25px) - 5px); }
  .dot.D {  left: calc((2 * 25px) - 12.5px - 5px + (3 * 1px)); }
  .dot.D.sharp { left: calc((2 * 25px) - 5px + (4 * 1px)); }
  .dot.E {  left: calc((3 * 25px) - 12.5px - 5px + (5 * 1px)); }
  .dot.F {  left: calc((4 * 25px) - 12.5px - 5px + (7 * 1px)); }
  .dot.F.sharp { left: calc((4 * 25px) - 5px + (7 * 1px)); }
  .dot.G {  left: calc((5 * 25px) - 12.5px - 5px + (9 * 1px)); }
  .dot.G.sharp { left: calc((5 * 25px) - 5px + (9 * 1px)); }
  .dot.A {  left: calc((6 * 25px) - 12.5px - 5px + (11 * 1px)); }
  .dot.A.sharp { left: calc((6 * 25px) - 5px + (12 * 1px)); }
  .dot.B {  left: calc((7 * 25px) - 12.5px - 5px + (13 * 1px)); }