@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");

:root {
  --primary-color: #008037;

  --light-primary-color: #4caf50;
  --light-primary-bg: #f2fff0;

  --contrast-color: #f57c00;
  --light-contrast-color: #ff8c1a;
  --light-contrast-bg: #ffb366;

  --secondary-color: #eeeeee;
  --black-color: #000;
  --gray-color: #7e7e7e;
  --light-gray-color: #eeeeee;



  --success-color: #006025;
  --info-color: #004c8c;
  --warning-color: #ffeb3b;
  --danger-color: #a80000;
  --error-color: #a80000;

  --light-color: #eeeeee;
  --dark-color: #343a40;
  --border-color: #7e7e7e;

  --font-size-small: 0.75rem;
  --font-size-normal: 1rem;
  --font-size-medium: 1.25rem;
  --font-size-big: 1.5rem;

  --primary-text-color: #fff;
  --secondary-text-color: #000;

  --mdc-theme-primary: #008037;
  --mdc-theme-secondary: #f57c00;
  --mdc-theme-error: #f44336;

  --menu-width: 15rem;
  --menu-height: 3rem;
}

html {
  width: 100%;
  height: 100vh;
  font-size: 25px;
}

body {
  height: 100vh;
  font-family: "Merriweather";
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
}

button {
  font-size: 1rem;
  padding: 5px 40px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  background-color: var(--primary-color);

  color: #fff;
  transition: all 0.05s ease-in-out;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

button:active {
  transform: translateY(2px);
  filter: brightness(110%);
}

button span {
  display: block;
}

a {
  text-decoration: none;
  color: var(--primary-color);
  transition: all 0.05s ease-in-out;
}

a:hover {
  color: var(--contrast-color);
  cursor: pointer;
}

input {
  font-size: 1rem;
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

input[type="checkbox"] {
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 1.25rem;
  height: 1.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

input[type="checkbox"]::before {
  content: "✓";
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: 0;
}

input[type="checkbox"]:checked::before {
  opacity: 1;
}

input[type="checkbox"]:checked {
  background-color: var(--primary-color);
}

label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.primary-button {
  background-color: var(--light-gray-color);
  border: 1px solid var(--black-color);
  color: var(--secondary-text-color);
  border-radius: 8px;
  min-height: 1em;
  padding: .25em 1em;
  font-size: 1em;
  flex-direction: row;
  flex-basis: 10em;
  flex-shrink: 0;
}

.transparent-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  padding: 0.25em;
  color: var(--primary-color);
  opacity: 0.3;
}

.hide-on-mobile {
  display: inherit;
}


@media (orientation: portrait) {
  html {
    font-size: 16px;
  }

  .round-mobile-button {
    min-height: 3rem;
    border-radius: 1.5rem;
    border-color: var(--primary-color);
    color: #f2fff0;
    background-color: var(--primary-color);
    flex-basis: 2em;
    align-items: center;
  }

  .transparent-button {
    font-size: 1.25rem;
  }

  .hide-on-mobile {
    display: none;
  }
}