.primary-button.user-status-button-obecność-selected {
    background-color: var(--primary-color);
    color: var(--primary-text-color);
}
.primary-button.user-status-button-nieobecność-selected {
    background-color: var(--danger-color);
    color: var(--primary-text-color);
}
.primary-button.user-status-button-spóźnienie-selected {
    background-color: var(--warning-color);
    color: var(--secondary-text-color);
}
.primary-button.user-status-button {
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
}