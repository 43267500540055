.sing-day-song-picker-bar {
    position: sticky;
    top: 4.65rem;
    height: 6em;
    width: 95vw;
    margin: 0 auto;
    padding: 0.5em;
    padding-top:1em;
    padding-bottom:1em;
    border-radius: 8px;
    background-color: var(--light-primary-color);
    background-image: linear-gradient(to right,rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)),linear-gradient(
        rgba(255, 255, 255, 1), 
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 1)
    );
    z-index: 1000;
}
.sing-day-song-picker-bar_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
}
.sing-day-song-picker-bar_song_buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
}
.sing-day-song-picker-button {
    font-size: 0.8em;
}

@media (orientation: portrait) {
    .sing-day-song-picker-bar {
        top: 0;
    }
}