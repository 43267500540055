.song-content-edit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    gap: 1rem;
  }
  .song-content-edit-title-container {
    width: 90%;
  }
  .song-content-edit-title {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
  }
  .song-content-edit-title input {
    display: flex;
    flex-basis: 20rem;
  }
  
  .song-content-edit-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    flex-shrink: 1;
    flex-grow: 1;
  }
  .song-content-edit-content-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;
    gap: 1rem;
  }
  .song-content-edit-content-item textarea {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1rem;
    border-radius: 8px;
    white-space: nowrap;
    overflow-x: auto;
    min-width: 25rem;
  }
  .song-content-edit-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
  }
  
  .song-content-edit-buttons-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
  }
  @media (orientation: portrait) {
    .song-content-edit-content-container {
      flex-direction: row;
    }
    .song-content-edit-content-item textarea {
      font-size: 0.75rem;
      min-width: 11rem;
    }
    .song-content-edit-buttons-container {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  