.day-management-page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    font-size:0.85rem;

  }
  .day-management-button-container
  {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    white-space: nowrap;
    justify-content: center;
    align-items: space-around;
  }
  @media (orientation: portrait) {
    .day-management-page-container {
      font-size:0.8rem;
    }
  }